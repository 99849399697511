import React,{ useState } from 'react';
import "./promoCard.scss";
import HomepageModal from "../homepageModal/HomepageModal";
import OneItemModal from "../oneItemModal/OneItemModal";
import ThreeItemModal from "../threeItemModal/ThreeItemModal";
import FourItemModal from "../fourItemModal/FourItemModal";
import TwoItemModal from "../twoItemModal/TwoItemModal";


const PromoCard = ({ title, description, image, discount, name, price }) => {
  const [openModal, setOpenModal] = useState(false);


  return (<>
    <div className="promoCard" onClick={() => setOpenModal(true)} >
      <img src={image} alt={title} className="promoCardImage" />
      <div className="promoCardContent">
        <h3 className="promoCardTitle">{title}</h3>
          <h5 className="promoCardName">{name}</h5>

        <p className="promoCardDescription">{description}</p>
        <p className="promoCardDiscount">{discount}</p>
      </div>
    </div>
      {(title === "DLA DZIECKA" ||
        title === "NUGGETSOWA UCZTA" ||
        title === "KULINARNE SAFARI") &&
        openModal && (
          <HomepageModal
            closeModal={setOpenModal}
            desc={description}
            name={name}
            price={price}
          />
        )}

{/* !! PROMOCJA DZIEŃ PIZZY */}

      {(title === "2xPIZZA O ROZMIARZE 30CM" ||
        title === "2xPIZZA O ROZMIARZE 40CM" || 
        title === "2xBURGER+2xFRYTKI" || 
        title === "2xZAPIEKANKA+2xFRYTKI"  ) &&
        openModal && (
          <TwoItemModal
            closeModal={setOpenModal}
            title={title}
            desc={description}
            nam={name}
            pri={price}
          />
        )}

      {/* !! PROMOCJA DZIEŃ PIZZY */}


      {(title === "DLA NIEJ" || title === "DLA NIEGO") && openModal && (
        <OneItemModal
          closeModal={setOpenModal}
          title={title}
          desc={description}
          nam={name}
          pri={price}
        />
      )}
      {(title === "TRZY ŚREDNIE, TRZY RAZY RADOŚĆ" ||
        title === "SMAKOWE TRÓJKI W BURGEROWEJ EDYCJI" ||
        title === "TRZY ZAPIEKANE CUDA" ||
        title === "TRZY RAZY WIĘCEJ AROMATU") &&
        openModal && (
          <ThreeItemModal
            closeModal={setOpenModal}
            title={title}
            desc={description}
            nam={name}
            pri={price}
          />
        )}
         {(title === "HAPPY BURGERFEST" ||
        title === "ZAPIEKANANZA" ||
        title === "FIESTA GRANDE" ||
        title === "GRANDIOSO COMBO" || 
        title === "MISTRZOWSKI BANKIET") &&
        openModal && (
          <FourItemModal
            closeModal={setOpenModal}
            title={title}
            desc={description}
            nam={name}
            pri={price}
          />
        )}</>
  );
};

export default PromoCard;
