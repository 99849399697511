import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const item = state.products.find(
        (item) =>
          item.id === action.payload.id &&
          item.size === action.payload.size &&
item.name === action.payload.name &&
          item.drink === action.payload.drink &&
          item.taste === action.payload.taste &&
          item.crust === action.payload.crust &&
          JSON.stringify(item.addedIngredients) ===
            JSON.stringify(action.payload.addedIngredients) &&
          JSON.stringify(item.excludedIngredients) ===
            JSON.stringify(action.payload.excludedIngredients) &&
          JSON.stringify(item.firstHalf.addedIngredients) ===
            JSON.stringify(action.payload.firstHalf.addedIngredients) &&
          JSON.stringify(item.secondHalf.addedIngredients2) ===
            JSON.stringify(action.payload.secondHalf.addedIngredients2) &&
          JSON.stringify(item.firstHalf.excludedIngredients) ===
            JSON.stringify(action.payload.firstHalf.excludedIngredients) &&
          JSON.stringify(item.secondHalf.excludedIngredients2) ===
            JSON.stringify(action.payload.secondHalf.excludedIngredients2) && 
 JSON.stringify(item.firstItem.name) ===
            JSON.stringify(action.payload.firstItem.name) &&
          JSON.stringify(item.firstItem.addedIngredients) ===
            JSON.stringify(action.payload.firstItem.addedIngredients) &&
          JSON.stringify(item.firstItem.excludedIngredients) ===
            JSON.stringify(action.payload.firstItem.excludedIngredients) &&
          JSON.stringify(item.firstItem.crust) ===
            JSON.stringify(action.payload.firstItem.crust) &&
          JSON.stringify(item.secondItem.name) ===
            JSON.stringify(action.payload.secondItem.name) &&
          JSON.stringify(item.secondItem.addedIngredients) ===
            JSON.stringify(action.payload.secondItem.addedIngredients) &&
          JSON.stringify(item.secondItem.excludedIngredients) ===
            JSON.stringify(action.payload.secondItem.excludedIngredients) &&
          JSON.stringify(item.secondItem.crust) ===
            JSON.stringify(action.payload.secondItem.crust) &&
          JSON.stringify(item.thirdItem.name) ===
            JSON.stringify(action.payload.thirdItem.name) &&
          JSON.stringify(item.thirdItem.addedIngredients) ===
            JSON.stringify(action.payload.thirdItem.addedIngredients) &&
          JSON.stringify(item.thirdItem.excludedIngredients) ===
            JSON.stringify(action.payload.thirdItem.excludedIngredients) &&
          JSON.stringify(item.thirdItem.crust) ===
            JSON.stringify(action.payload.thirdItem.crust) &&
          JSON.stringify(item.fourthItem.name) ===
            JSON.stringify(action.payload.fourthItem.name) &&
          JSON.stringify(item.fourthItem.addedIngredients) ===
            JSON.stringify(action.payload.fourthItem.addedIngredients) &&
          JSON.stringify(item.fourthItem.excludedIngredients) ===
            JSON.stringify(action.payload.fourthItem.excludedIngredients) &&
          JSON.stringify(item.fourthItem.crust) ===
            JSON.stringify(action.payload.fourthItem.crust)
      );
      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        state.products.push(action.payload);
      }
    },
    removeItem: (state, action) => {
      state.products = state.products.filter(
        (item) =>
 item.id !== action.payload.id ||
          item.size !== action.payload.size ||
item.name !== action.payload.name ||
          item.drink !== action.payload.drink ||
          item.taste !== action.payload.taste ||
          item.crust !== action.payload.crust ||
          JSON.stringify(item.addedIngredients) !==
            JSON.stringify(action.payload.addedIngredients) ||
          JSON.stringify(item.excludedIngredients) !==
            JSON.stringify(action.payload.excludedIngredients) ||
          JSON.stringify(item.firstHalf.addedIngredients) !==
            JSON.stringify(action.payload.firstHalf.addedIngredients) ||
          JSON.stringify(item.firstHalf.excludedIngredients) !==
            JSON.stringify(action.payload.firstHalf.excludedIngredients) ||
          JSON.stringify(item.secondHalf.addedIngredients2) !==
            JSON.stringify(action.payload.secondHalf.addedIngredients2) ||
          JSON.stringify(item.secondHalf.excludedIngredients2) !==
            JSON.stringify(action.payload.secondHalf.excludedIngredients2) ||
          JSON.stringify(item.firstItem.name) !==
            JSON.stringify(action.payload.firstItem.name) ||
          JSON.stringify(item.firstItem.addedIngredients) !==
            JSON.stringify(action.payload.firstItem.addedIngredients) ||
          JSON.stringify(item.firstItem.excludedIngredients) !==
            JSON.stringify(action.payload.firstItem.excludedIngredients) ||
          JSON.stringify(item.firstItem.crust) !==
            JSON.stringify(action.payload.firstItem.crust) ||
          JSON.stringify(item.secondItem.name) !==
            JSON.stringify(action.payload.secondItem.name) ||
          JSON.stringify(item.secondItem.addedIngredients) !==
            JSON.stringify(action.payload.secondItem.addedIngredients) ||
          JSON.stringify(item.secondItem.excludedIngredients) !==
            JSON.stringify(action.payload.secondItem.excludedIngredients) ||
          JSON.stringify(item.secondItem.crust) !==
            JSON.stringify(action.payload.secondItem.crust) ||
          JSON.stringify(item.thirdItem.name) !==
            JSON.stringify(action.payload.thirdItem.name) ||
          JSON.stringify(item.thirdItem.addedIngredients) !==
            JSON.stringify(action.payload.thirdItem.addedIngredients) ||
          JSON.stringify(item.thirdItem.excludedIngredients) !==
            JSON.stringify(action.payload.thirdItem.excludedIngredients) ||
          JSON.stringify(item.thirdItem.crust) !==
            JSON.stringify(action.payload.thirdItem.crust) ||
          JSON.stringify(item.fourthItem.name) !==
            JSON.stringify(action.payload.fourthItem.name) ||
          JSON.stringify(item.fourthItem.addedIngredients) !==
            JSON.stringify(action.payload.fourthItem.addedIngredients) ||
          JSON.stringify(item.fourthItem.excludedIngredients) !==
            JSON.stringify(action.payload.fourthItem.excludedIngredients) ||
          JSON.stringify(item.fourthItem.crust) !==
            JSON.stringify(action.payload.fourthItem.crust)
      );
    },
    resetCart: (state) => {
      state.products = [];
    },
    quantityChange: (state, action) =>{
      const item = state.products.find(
        (item) =>
          item.id === action.payload.id &&
          item.size === action.payload.size &&
item.name === action.payload.name &&
          item.drink === action.payload.drink &&
          item.taste === action.payload.taste &&
          item.crust === action.payload.crust &&
          JSON.stringify(item.addedIngredients) ===
            JSON.stringify(action.payload.addedIngredients) &&
          JSON.stringify(item.excludedIngredients) ===
            JSON.stringify(action.payload.excludedIngredients) &&
          JSON.stringify(item.firstHalf.addedIngredients) ===
            JSON.stringify(action.payload.firstHalf.addedIngredients) &&
          JSON.stringify(item.secondHalf.addedIngredients2) ===
            JSON.stringify(action.payload.secondHalf.addedIngredients2) &&
          JSON.stringify(item.firstHalf.excludedIngredients) ===
            JSON.stringify(action.payload.firstHalf.excludedIngredients) &&
          JSON.stringify(item.secondHalf.excludedIngredients2) ===
            JSON.stringify(action.payload.secondHalf.excludedIngredients2) &&
          JSON.stringify(item.firstItem.name) ===
            JSON.stringify(action.payload.firstItem.name) &&
          JSON.stringify(item.firstItem.addedIngredients) ===
            JSON.stringify(action.payload.firstItem.addedIngredients) &&
          JSON.stringify(item.firstItem.excludedIngredients) ===
            JSON.stringify(action.payload.firstItem.excludedIngredients) &&
          JSON.stringify(item.firstItem.crust) ===
            JSON.stringify(action.payload.firstItem.crust) &&
          JSON.stringify(item.secondItem.name) ===
            JSON.stringify(action.payload.secondItem.name) &&
          JSON.stringify(item.secondItem.addedIngredients) ===
            JSON.stringify(action.payload.secondItem.addedIngredients) &&
          JSON.stringify(item.secondItem.excludedIngredients) ===
            JSON.stringify(action.payload.secondItem.excludedIngredients) &&
          JSON.stringify(item.secondItem.crust) ===
            JSON.stringify(action.payload.secondItem.crust) &&
          JSON.stringify(item.thirdItem.name) ===
            JSON.stringify(action.payload.thirdItem.name) &&
          JSON.stringify(item.thirdItem.addedIngredients) ===
            JSON.stringify(action.payload.thirdItem.addedIngredients) &&
          JSON.stringify(item.thirdItem.excludedIngredients) ===
            JSON.stringify(action.payload.thirdItem.excludedIngredients) &&
          JSON.stringify(item.thirdItem.crust) ===
            JSON.stringify(action.payload.thirdItem.crust) &&
          JSON.stringify(item.fourthItem.name) ===
            JSON.stringify(action.payload.fourthItem.name) &&
          JSON.stringify(item.fourthItem.addedIngredients) ===
            JSON.stringify(action.payload.fourthItem.addedIngredients) &&
          JSON.stringify(item.fourthItem.excludedIngredients) ===
            JSON.stringify(action.payload.fourthItem.excludedIngredients) &&
          JSON.stringify(item.fourthItem.crust) ===
            JSON.stringify(action.payload.fourthItem.crust)
      );
      if (item) {
        item.quantity = action.payload.quantity;
      }
    }
  },
});

export const { addToCart, removeItem, resetCart, quantityChange } = cartSlice.actions;

export default cartSlice.reducer;
