export const streets = [
    { value: "baraniaka", label: "abpa Antoniego Baraniaka", id: "street" },
    { value: "mickiewicza", label: "Adama Mickiewicza", id: "street" },
 { value: "przedszkolna", label: "Przedszkolna", id: "street" },
    { value: "podleśna", label: "Podleśna", id: "street" },
    {
      value: "aleja koszewskiego",
      label: "Aleja Stanisława Koszewskiego",
      id: "street",
    },
    {
      value: "aleja kościuszki",
      label: "Aleja Tadeusza Kościuszki",
      id: "street",
    },
    { value: "bukowieckiej", label: "Aleksandry Bukowieckiej", id: "street" },
    { value: "grątkowskiej", label: "Anny Grątkowskiej", id: "street" },
    { value: "kaźmierczaka", label: "Antoniego Kaźmierczaka", id: "street" },
    { value: "armii krajowej", label: "Armii Krajowej", id: "street" },
    { value: "bernardyńska", label: "Bernardyńska", id: "street" },
    { value: "boczna", label: "Boczna", id: "street" },
    { value: "igłowicza", label: "Bolesława Igłowicza", id: "street" },
    { value: "prusa", label: "Bolesława Prusa", id: "street" },
    { value: "brzozowa", label: "Brzozowa", id: "street" },
    { value: "bukowa", label: "Bukowa", id: "street" },
    { value: "cedrowa", label: "Cedrowa", id: "street" },
    { value: "cienista", label: "Cienista", id: "street" },
    { value: "cisowa", label: "Cisowa", id: "street" },
    { value: "czempińska", label: "Czempińska", id: "street" },
    { value: "czereśniowa", label: "Czereśniowa", id: "street" },
    { value: "długa", label: "Długa", id: "street" },
    { value: "dworcowa", label: "Dworcowa", id: "street" },
    { value: "działkowa", label: "Działkowa", id: "street" },
    { value: "fabiańczyka", label: "Edwarda Fabiańczyka", id: "street" },
    { value: "fabryczna", label: "Fabryczna", id: "street" },
    { value: "nowowiejskiego", label: "Feliksa Nowowiejskiego", id: "street" },
    { value: "stamma", label: "Feliksa Stamma", id: "street" },
    { value: "marciniaka", label: "Floriana Marciniaka", id: "street" },
    { value: "winowicza", label: "Franciszka Winowicza", id: "street" },
    { value: "narutowicza", label: "Gabriela Narutowicza", id: "street" },
    { value: "garbarska", label: "Garbarska", id: "street" },
    {
      value: "chłapowskiego",
      label: "Gen. Dezyderego Chłapowskiego",
      id: "street",
    },
    {
      value: "taczanowskiego",
      label: "Gen. Edmunda Taczanowskiego",
      id: "street",
    },
    {
      value: "pułaskiego",
      label: "Generała Kazimierza Pułaskiego",
      id: "street",
    },
    { value: "gostyńska", label: "Gostyńska", id: "street" },
    { value: "górna", label: "Górna", id: "street" },
    { value: "graniczna", label: "Graniczna", id: "street" },
    { value: "grodziska", label: "Grodziska", id: "street" },
    { value: "gryczana", label: "Gryczana", id: "street" },
    { value: "dąbrowskiego", label: "Henryka Dąbrowskiego", id: "street" },
    { value: "sienkiewicza", label: "Henryka Sienkiewicza", id: "street" },
    { value: "kołłątaja", label: "Hugo Kołłątaja", id: "street" },
    { value: "kościańska", label: "Kościańska", id: "street" },
    { value: "krasickiego", label: "Ignacego Krasickiego", id: "street" },
    { value: "richtera", label: "Ignacego Richtera", id: "street" },
    { value: "kasprowicza", label: "Jana Kasprowicza", id: "street" },
    { value: "kilińskiego", label: "Jana Kilińskiego", id: "street" },
    { value: "kochanowskiego", label: "Jana Kochanowskiego", id: "street" },
    { value: "czaplickiego", label: "Janusza Czaplickiego", id: "street" },
    { value: "korczaka", label: "Janusza Korczaka", id: "street" },
    { value: "iwaszkiewicza", label: "Jarosława Iwaszkiewicza", id: "street" },
    { value: "jasna", label: "Jasna", id: "street" },
    { value: "fellmanna", label: "Jerzego Fellmanna", id: "street" },
    { value: "jesionowa", label: "Jesionowa", id: "street" },
    { value: "jęczmienna", label: "Jęczmienna", id: "street" },
    { value: "jodłowa", label: "Jodłowa", id: "street" },
    { value: "chociszewskiego", label: "Józefa Chociszewskiego", id: "street" },
    {
      value: "kraszewskiego",
      label: "Józefa Ignacego Kraszewskiego",
      id: "street",
    },
    { value: "niemcewicza", label: "Juliana Ursyna Niemcewicza", id: "street" },
    { value: "słowackiego", label: "Juliusza Słowackiego", id: "street" },
    { value: "bojanowskiego", label: "Karola Bojanowskiego", id: "street" },
    { value: "kurpińskiego", label: "Karola Kurpińskiego", id: "street" },
    { value: "marcinkowskiego", label: "Karola Marcinkowskiego", id: "street" },
    { value: "kątna", label: "Kątna", id: "street" },
    { value: "kruszewskiego", label: "Klemensa Kruszewskiego", id: "street" },
    { value: "klonowa", label: "Klonowa", id: "street" },
    { value: "kosynierów", label: "Kosynierów", id: "street" },
    { value: "kościelna", label: "Kościelna", id: "street" },
    { value: "krańcowa", label: "Krańcowa", id: "street" },
    { value: "kręta", label: "Kręta", id: "street" },
    { value: "krótka", label: "Krótka", id: "street" },
    { value: "krzywa", label: "Krzywa", id: "street" },
    {
      value: "wyszyńskiego",
      label: "Ks. Prym. Stefana Wyszyńskiego",
      id: "street",
    },
    {
      value: "surzyńskiego",
      label: "Księdza Józefa Surzyńskiego",
      id: "street",
    },
    { value: "stępniaka", label: "Księdza Leona Stępniaka", id: "street" },
    {
      value: "bączkowskiego",
      label: "Księdza Piotra Bączkowskiego",
      id: "street",
    },
    { value: "kwiatowa", label: "Kwiatowa", id: "street" },
    { value: "ciszaka", label: "Leona Ciszaka", id: "street" },
    { value: "łąkowa", label: "Łąkowa", id: "street" },
    { value: "żółtowskiego", label: "Marcelego Żółtowskiego", id: "street" },
    { value: "konopnickiej", label: "Marii Konopnickiej", id: "street" },
    {
      value: "skłodowskiej-curie",
      label: "Marii Skłodowskiej-Curie",
      id: "street",
    },
    {
      value: "piłsudskiego",
      label: "Marszałka Józefa Piłsudskiego",
      id: "street",
    },
    { value: "balcera", label: "Mieczysława Balcera", id: "street" },
    { value: "kopernika", label: "Mikołaja Kopernika", id: "street" },
    { value: "reja", label: "Mikołaja Reja", id: "street" },
    { value: "młyńska", label: "Młyńska", id: "street" },
    { value: "modrzewiowa", label: "Modrzewiowa", id: "street" },
    { value: "mostowa", label: "Mostowa", id: "street" },
    { value: "nacławska", label: "Nacławska", id: "street" },
    { value: "nadobrzańska", label: "Nadobrzańska", id: "street" },
    { value: "ogrodowa", label: "Ogrodowa", id: "street" },
    { value: "okrężna", label: "Okrężna", id: "street" },
    { value: "orląt polskich", label: "Orląt Polskich", id: "street" },
    {
      value: "osiedle bitwy warszawskiej",
      label: "Osiedle Bitwy Warszawskiej 1920",
      id: "street",
    },
    {
      value: "osiedle sikorskiego",
      label: "Osiedle Gen. Wł. Sikorskiego",
      id: "street",
    },
    {
      value: "osiedle nad łąkami",
      label: "Osiedle Nad Łąkami",
      id: "street",
    },
    {
      value: "osiedle literatów",
      label: "Osiedle Literatów",
      id: "street",
    },
    {
      value: "osiedle andersa",
      label: "Osiedle Generała Władysława Andersa",
      id: "street",
    },
    {
      value: "osiedle konstytucji 3 maja",
      label: "Osiedle Konstytucji 3 Maja",
      id: "street",
    },
    {
      value: "osiedle ogrody",
      label: "Osiedle Ogrody",
      id: "street",
    },
    {
      value: "osiedle piastowskie",
      label: "Osiedle Piastowskie",
      id: "street",
    },
    {
      value: "osiedle jagiellońskie",
      label: "Osiedle Jagiellońskie",
      id: "street",
    },
    { value: "piaskowa", label: "Piaskowa", id: "street" },
    { value: "piastowska", label: "Piastowska", id: "street" },
    {
      value: "plac paderewskiego",
      label: "Plac Ignacego Paderewskiego",
      id: "street",
    },
    { value: "plac wolności", label: "Plac Wolności", id: "street" },
    { value: "pileckiego", label: "płk. Witolda Pileckiego", id: "street" },
    { value: "podgórna", label: "Podgórna", id: "street" },
    { value: "pogodna", label: "Pogodna", id: "street" },
    { value: "polna", label: "Polna", id: "street" },
    { value: "południowa", label: "Południowa", id: "street" },
    { value: "poprzeczna", label: "Poprzeczna", id: "street" },
    {
      value: "powstańców wielkopolskich",
      label: "Powstańców Wielkopolskich",
      id: "street",
    },
    { value: "poznańska", label: "Poznańska", id: "street" },
    { value: "północna", label: "Północna", id: "street" },
    { value: "półwiejska", label: "Półwiejska", id: "street" },
    { value: "promienista", label: "Promienista", id: "street" },
    { value: "prosta", label: "Prosta", id: "street" },
    { value: "przemysłowa", label: "Przemysłowa", id: "street" },
    { value: "pszenna", label: "Pszenna", id: "street" },
    { value: "rezerwy skautowej", label: "Rezerwy Skautowej", id: "street" },
    { value: "rolna", label: "Rolna", id: "street" },
    { value: "traugutta", label: "Romualda Traugutta", id: "street" },
    { value: "różana", label: "Różana", id: "street" },
    { value: "rynek", label: "Rynek", id: "street" },
    { value: "berwińskiego", label: "Ryszarda Berwińskiego", id: "street" },
    { value: "składowa", label: "Składowa", id: "street" },
    { value: "słoneczna", label: "Słoneczna", id: "street" },
    { value: "moniuszki", label: "Stanisława Moniuszki", id: "street" },
    { value: "sosnowa", label: "Sosnowa", id: "street" },
    {
      value: "szczepanowskiego",
      label: "Stanisława Szczepanowskiego",
      id: "street",
    },
    {
      value: "wojciechowskiego",
      label: "Stanisława Wojciechowskiego",
      id: "street",
    },
    { value: "wyspiańskiego", label: "Stanisława Wyspiańskiego", id: "street" },
    { value: "żeromskiego", label: "Stefana Żeromskiego", id: "street" },
    { value: "strzelecka", label: "Strzelecka", id: "street" },
    { value: "szewska", label: "Szewska", id: "street" },
    { value: "szkolna", label: "Szkolna", id: "street" },
    { value: "szpitalna", label: "Szpitalna", id: "street" },
    { value: "śmigielska", label: "Śmigielska", id: "street" },
    { value: "świerkowa", label: "Świerkowa", id: "street" },
    { value: "świętego ducha", label: "Świętego Ducha", id: "street" },
    { value: "świętego jana", label: "Świętego Jana", id: "street" },
    { value: "zawadzkiego", label: "Tadeusza Zawadzkiego", id: "street" },
    { value: "topolowa", label: "Topolowa", id: "street" },
    { value: "torowa", label: "Torowa", id: "street" },
    { value: "towarowa", label: "Towarowa", id: "street" },
    { value: "uczniowska", label: "Uczniowska", id: "street" },
    { value: "czajki", label: "Wawrzyńca Czajki", id: "street" },
    { value: "wiatraczna", label: "Wiatraczna", id: "street" },
    { value: "wichrowa", label: "Wichrowa", id: "street" },
    { value: "wielichowska", label: "Wielichowska", id: "street" },
    { value: "wierzbowa", label: "Wierzbowa", id: "street" },
    { value: "wiśniowa", label: "Wiśniowa", id: "street" },
 { value: "dębowa", label: "Dębowa", id: "street" },
  { value: "leśna", label: "Leśna", id: "street" },   
 { value: "radomskiego", label: "Władysława Radomskiego", id: "street" },
    { value: "broniewskiego", label: "Władysława Broniewskiego", id: "street" },
    {
      value: "reymonta",
      label: "Władysława Stanisława Reymonta",
      id: "street",
    },
    { value: "maya", label: "Wojciecha Maya", id: "street" },
    { value: "wodna", label: "Wodna", id: "street" },
    { value: "wojska polskiego", label: "Wojska Polskiego", id: "street" },
    { value: "wrocławska", label: "Wrocławska", id: "street" },
    { value: "wschodnia", label: "Wschodnia", id: "street" },
    { value: "wyzwolenia", label: "Wyzwolenia", id: "street" },
    { value: "zachodnia", label: "Zachodnia", id: "street" },
    { value: "zielona", label: "Zielona", id: "street" },
    { value: "sierakowskiego", label: "Zygmunta Sierakowskiego", id: "street" },
    { value: "żarnowa", label: "Żarnowa", id: "street" },
  { value: "parkowa", label: "Parkowa", id: "street" },
    { value: "żwirki i wigury", label: "Żwirki i Wigury", id: "street" },
  ];
