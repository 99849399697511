import React from "react";
import "./alergeny.scss";
import Sidebar from "../../components/sidebar/Sidebar";

const Alergeny = () => {
  return (
    <div className="alergeny">
      <Sidebar />
      <div className="alergenyContainer">
        <div className="alergenyWrapper">
          <h4>Wykaz alergenów:</h4>
          <span>
            Wszystkie oferowane pizze zawierają gluten, gorczyce, seler, soje
            i mleko łącznie z laktozą. Wędliny mogą zawierać śladowe ilości:
            glutenu, soi, mleka (łącznie z laktozą), selera, gorczycy, błonnika
            spanszennego, według oświadczeń producentów.
          </span>
          <h4>Wykaz alergenów w sosach:</h4>
          <span>– meksykański: seler, jaja, soja, mleko, gorczyca, sezam</span>
          <span>– czosnkowy: mleko, gorczyca </span>
          <span>– 1000 wysp: gorczyca, mleko</span>
          <span>– słodko-kwaśny: soja, pszenica</span>
          <span>– ketchup: seler</span>
          <span>– bbq: soja, pszenica, jaja, mleko, seler</span>
          <span>– amerykański: gorczyca, mleko</span>
          <span>– bazyliowy: gorczyca, mleko</span>
        </div>
      </div>
    </div>
  );
};

export default Alergeny;
