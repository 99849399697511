import React, { useState, useEffect } from "react";
import "./homepagePopup.scss";

const HomepagePopup = () => {
  const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
    // Check if the popup has been shown today
    const lastShownDate = localStorage.getItem('popupLastShown');
    const currentDate = new Date().toLocaleDateString();

    if (lastShownDate !== currentDate) {
      // Reset the count of times shown today
      localStorage.setItem('popupCount', '0');
    }

    // Check if the popup can be shown today
    const popupCount = localStorage.getItem('popupCount') || 0;
    if (parseInt(popupCount) < 2) {
      const timeoutId = setTimeout(() => {
        setShowPopup(false);

        // Update local storage with the current date
        localStorage.setItem('popupLastShown', currentDate);

        // Increment the count of times shown today
        localStorage.setItem('popupCount', parseInt(popupCount) + 1);
      }, 3000);

      return () => clearTimeout(timeoutId);
    }

  }, []);

  const handleClose = () => {
    setShowPopup(false);
  };

  return (
    <div>
      {showPopup && (
        <div className="popup-container" onClick={handleClose}>
          <div className="popup">
            <img
             src="https://images2.imgbox.com/ab/3b/WBdSe3ez_o.jpg"
             alt="nowosci"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default HomepagePopup;
