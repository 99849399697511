import React, { useState } from "react";
import "./homepageModal.scss";
import { addToCart } from "../../redux/cartReducer";
import { useDispatch } from "react-redux";

const HomepageModal = ({ closeModal, desc, name, price }) => {
  const dispatch = useDispatch();

  const scrollToTop = () => {
    closeModal(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="hompageModalContainer">
        <div className="modalBox">
          <div className="titleModalHompage">
            <div className="leftTitle">
              <h5>{name}</h5>
            </div>
            <div className="rightTitle">
              <button className="closeButton" onClick={() => closeModal(false)}>
                X
              </button>
            </div>
          </div>
          <div className="modalBoxContent">
            <p>{desc}</p>
            <button
              className="addToCartButton"
              onClick={() => {
                dispatch(
                  addToCart({
                    id: name,
                    name: name,
                    img: "https://res.cloudinary.com/dqknlkpku/image/upload/v1700072781/upload/znzgrmrbpugdiroiefkd.jpg",
                    category: "promocje",
                    addedIngredients: [],
                    excludedIngredients: [],
                    firstHalf: {
                      addedIngredients: [],
                      excludedIngredients: [],
                    },
                    secondHalf: {
                      addedIngredients2: [],
                      excludedIngredients2: [],
                    },
                    taste: "",
                    drink: "",
                    crust: "",
                    size: "medium",
                    sup: 0,
                    price,
                    quantity: 1,
                    firstItem: {
                      name: "",
                      ingredients: "",
                      addedIngredients: "",
                      excludedIngredients: "",
                      crust: "",
                    },
                    secondItem: {
                      name: "",
                      ingredients: "",
                      addedIngredients: "",
                      excludedIngredients: "",
                      crust: "",
                    },
                    thirdItem: {
                      name: "",
                      ingredients: "",
                      addedIngredients: "",
                      excludedIngredients: "",
                      crust: "",
                    }, fourthItem: {
                      name: "",
                      ingredients: "",
                      addedIngredients: "",
                      excludedIngredients: "",
                      crust: "",
                    },
                  })
                );
                scrollToTop();
              }}
            >
              <p className="price">{price} zł</p>Dodaj do koszyka
            </button>
          </div>
        </div>
        <div
          className="backgroundModal"
          onClick={() => closeModal(false)}
        ></div>
      </div>
    </>
  );
};

export default HomepageModal;
