import "./terms.scss";

const Terms = ({ closeTerms }) => {
  return (
    <div className="termsContainer">
      <div className="termsBox">
        {/* przerobić ten tekst! zły html */}
        <p className="message">
          <p>
            <strong>§1&nbsp;</strong>
            <strong>Postanowienia wstępne</strong>
          </p>

          <ol>
            <li>
              Sklep internetowy Pizzeria Omika, dostępny pod adresem
              internetowym www.omika.pl, prowadzony jest przez Tomasza Walicha
              prowadzącego działalność gospodarczą pod firmą Przedsiębiorstwo
              Handlowo Usługowe Tomasz Walich, wpisaną do Centralnej Ewidencji i
              Informacji o Działalności Gospodarczej (CEIDG) prowadzonej przez
              ministra właściwego ds. gospodarki, NIP 777-305-90-97,
              REGON&nbsp;369759526
            </li>
            <li>
              Niniejszy regulamin skierowany jest do Konsumentów i określa
              zasady i tryb zawierania z Konsumentem Umowy Sprzedaży na
              odległość za pośrednictwem Sklepu.
            </li>
            <li>
              Korzystanie z serwisu www.omika.pl jest bezpłatne. Płatność za
              zamówione produkty w pizzerii Omika określona jest zawsze
              szczegółowo w podsumowaniu zamówienia.
            </li>
            <li>
              Zamówienie w pizzerii oznacza zawarcie umowy pomiędzy pizzerią, a
              użytkownikiem. Umowa zostaje zawarta w chwili potwierdzenia
              użytkownikowi przez Omika faktu przyjęcia zamówienia.
            </li>
            <li>
              Regulamin określa prawa i obowiązki Pizzeria Omika i użytkowników
              serwisu www.omika.pl
            </li>
            <li>
              Korzystanie z serwisu www.omika.pl oznacza zgodę na postanowienia
              regulaminu. Każdy użytkownik powinien zapoznać się z treścią
              regulaminu przed rozpoczęciem korzystania z serwisu. Użytkownik,
              który nie wyraża zgody na postanowienia regulaminu powinien
              zaprzestać korzystania z serwisu.
            </li>
            <li>
              Pizzeria Omika w ramach serwisu www.omika.pl nie świadczy usług
              telekomunikacyjnych w rozumieniu Ustawy z dnia 16 lipca 2004 r.
              Prawo telekomunikacyjne (Dz.U. z 2004 nr 171 poz. 1800 z późn.
              zm.), w związku z powyższym wszelkie reklamacje związane z tego
              rodzaju usługami, użytkownik powinien kierować do operatora
              telekomunikacyjnego, z którego usług korzysta.
            </li>
          </ol>
          <p>
            <strong>§2 Wymagania techniczne</strong>
          </p>

          <ol>
            <li>
              Korzystanie z serwisu wymaga posiadania przez użytkownika
              urządzenia pozwalającego na dostęp do sieci Internet, przeglądarki
              Internet Explorer 8, Chrome, Safari, Firefox, Opera lub ich
              nowszych wersji oraz oprogramowania akceptującego pliki cookies.
            </li>
          </ol>
          <p>
            <strong>§3 Wartość intelektualna i licencje</strong>
          </p>

          <ol>
            <li>
              Pizzeria Omika posiada wszelkie prawa własności intelektualnej do
              serwisu www.omika.pl, w szczególności Pizzeria Omika posiada
              wszelkie prawa do nazwy, domeny, układu, elementów graficznych
              oraz wszelkich innych twórczych elementów serwisu.
            </li>
          </ol>
          <p>
            <strong>§4 Korzystanie z serwisu www.omika.pl</strong>
          </p>

          <ol>
            <li>Korzystanie z serwisu www.omika.pl jest dobrowolne.</li>
            <li>
              Serwis www.omika.pl jest przeznaczony dla osób fizycznych
              posiadających pełną zdolność do czynności prawnych. Użytkownikiem
              może być również osoba małoletnia i/lub nieposiadająca pełnej
              zdolności do czynności prawnych, pod warunkiem uzyskania zgody
              przedstawiciela ustawowego. Osoba prawna może mieć własny profil
              pod warunkiem wskazania jednej osoby fizycznej jako użytkownika.
            </li>
            <li>
              Składanie zamówień poprzez serwis www.omika.pl jest możliwe po
              dokonaniu rejestracji dla użytkowników zalogowanych jak i bez
              dokonywania rejestracji.
            </li>
            <li>
              W celu rejestracji użytkownik powinien podać swój aktywny adres
              e-mail, imię, nazwisko, numer telefonu kontaktowego, dane adresowe
              oraz unikalne hasło, a także przejść procedurę weryfikacji
              wskazaną przez Pizzeria Omika
            </li>
            <li>
              W przypadku pozytywnej rejestracji Pizzeria Omika utworzy profil
              użytkownika, w którym przechowywać będzie jego ustawienia
              konfiguracyjne oraz dane podane przez niego w czasie rejestracji.
              Dane te będą niewidoczne dla innych użytkowników.
            </li>
            <li>
              W celu złożenia zamówienia użytkownik niezarejestrowany powinien
              podać swoje imię, nazwisko numer telefonu kontaktowego oraz adres
              do dostawy, a także wybrać sposób płatności.
            </li>
          </ol>
          <p>
            <strong>
              §5 Zakres działań Pizzerii Omika w ramach serwisu www.omika.pl
            </strong>
          </p>

          <ol>
            <li>
              Pizzeria Omika może zamieszczać w serwisie www.omika.pl reklamy
              oraz inne elementy promocji towarów i usług osób trzecich.
            </li>
            <li>
              Pizzeria Omika dokłada wszelkich starań, aby serwis www.omika.pl
              działał poprawnie, ale nie gwarantuje ich ciągłej dostępności.
            </li>
            <li>
              Pizzeria Omika zastrzega sobie prawo do ingerencji w profil
              użytkownika w celu usunięcia nieprawidłowości w funkcjonowaniu
              serwisu www.omika.pl oraz zakłóceń lub problemów w funkcjonowaniu
              profilu.
            </li>
            <li>
              Pizzeria Omika zastrzega sobie prawo zakończenia świadczenia
              usługi serwisu www.omika.pl
            </li>
          </ol>
          <p>
            <strong>§6 Zamówienia i płatności</strong>
          </p>

          <ol>
            <li>
              W celu złożenia zamówienia należy:
              <ul>
                <li>zalogować się do serwisu www.omika.pl (opcjonalnie);</li>
                <li>
                  wybrać produkt będący przedmiotem zamówienia, a następnie
                  kliknąć przycisk „Do koszyka” (lub równoznaczny);
                </li>
                <li>
                  zalogować się lub skorzystać z możliwości złożenia zamówienia
                  bez rejestracji;
                </li>
                <li>
                  jeżeli wybrano możliwość złożenia zamówienia bez rejestracji -
                  wypełnić formularz zamówienia poprzez wpisanie danych odbiorcy
                  zamówienia oraz adresu, na który ma nastąpić dostawa produktu
                  lub wybrać opcję odbioru osobistego
                </li>
                <li>kliknąć przycisk “Zamawiam i płacę” (lub równoznaczny)</li>
                <li>
                  wybrać jeden z dostępnych sposobów płatności i w zależności od
                  sposobu płatności, opłacić zamówienie w określonym terminie, z
                  zastrzeżeniem § 4 pkt 2.
                </li>
              </ul>
            </li>
            <li>
              Szczegółowe informacje na temat metod dostawy oraz akceptowalnych
              metod płatności znajdują się na stronach serwisu www.omika.pl.
            </li>
            <li>
              W przypadku korzystania z usług świadczonych drogą elektroniczną
              przez serwis tpay.com należy przestrzegać&nbsp;
              <a href="https://secure.tpay.com/partner/pliki/regulamin.pdf">
                Regulaminu korzystania z Serwisu
              </a>
            </li>
          </ol>
          <p>
            <strong>§7 Dane osobowe</strong>
          </p>

          <ol>
            <li>
              Administratorem danych osobowych użytkowników zbieranych za
              pośrednictwem serwisu internetowego www.omika.pl jest
              Przedsiębiorstwo Handlowo Usługowe Tomasz Walich.
            </li>
            <li>
              Dane osobowe użytkowników zbierane przez administratora za
              pośrednictwem serwisu internetowego www.omika.pl zbierane są w
              celu realizacji umowy sprzedaży produktu, a jeżeli użytkownik
              wyrazi na to zgodę - także w celu marketingowym
            </li>
            <li>
              Odbiorcami danych osobowych uzytkowników serwisu internetowego
              www.omika.pl może być podmiot obsługujący płatności elektroniczne
              lub kartą płatniczą.
            </li>
            <li>
              Użytkownik ma prawo dostępu do treści swoich danych oraz ich
              poprawiania.
            </li>
            <li>
              Podanie danych osobowych jest dobrowolne, aczkolwiek niepodanie
              wskazanych w Regulaminie danych osobowych niezbędnych do zawarcia
              umowy sprzedaży skutkuje brakiem możliwości zawarcia tejże umowy.
            </li>
            <li>
              Podstawą prawną&nbsp;
              <span>
                przetwarzania danych osobowych jest uzasadniony interes
                administratora (
              </span>
              <span>art. 6 ust. 1 lit f RODO).</span>
            </li>
          </ol>
          <p>
            <strong>§8 Reklamacje</strong>
          </p>

          <ol>
            <li>
              Usługobiorca ma prawo do złożenia reklamacji w sprawach usług
              świadczonych drogą elektroniczną.
            </li>
            <li>
              Reklamacja musi mieć formę pisemną i może zostać złożona w
              terminie 30 dni od wykonania usługi.
            </li>
            <li>
              Reklamacja zostanie rozpatrzona w ciągu 14 dni od momentu jej
              otrzymania. O rezultatach usługobiorca zostanie zawiadomiony
              pisemnie lub za pośrednictwem poczty elektronicznej.
            </li>
          </ol>
          <p>
            <strong>§9 Postanowienia końcowe</strong>
          </p>

          <ol>
            <li>
              Umowy zawierane poprzez serwis internetowy www.omika.pl zawierane
              są w języku polskim.
            </li>
            <li>
              Przedsiębiorstwo Handlowo Usługowe Tomasz Walich zastrzega sobie
              prawo do dokonywania zmian Regulaminu z ważnych przyczyn to jest:
              zmiany przepisów prawa, zmiany sposobów płatności i dostaw - w
              zakresie, w jakim te zmiany wpływają na realizację postanowień
              niniejszego Regulaminu. O każdej zmianie Przedsiębiorstwo Handlowo
              Usługowe Tomasz Walich poinformuje użytkownika serwisu z co
              najmniej 7 dniowym wyprzedzeniem.
            </li>
            <li>
              W sprawach nieuregulowanych w niniejszym Regulaminie mają
              zastosowanie powszechnie obowiązujące przepisy prawa polskiego, w
              szczególności: Kodeksu cywilnego; ustawy o świadczeniu usług drogą
              elektroniczną; ustawy o prawach konsumenta, ustawy o ochronie
              danych osobowych.
            </li>
            <li>
              Użytkownik ma prawo skorzystać z pozasądowych sposobów
              rozpatrywania reklamacji i dochodzenia roszczeń. W tym celu może
              złożyć skargę za pośrednictwem unijnej platformy internetowej ODR
              dostępnej pod adresem:{" "}
              <a href="http://ec.europa.eu/consumers/odr/">
                http://ec.europa.eu/consumers/odr/
              </a>
              .
            </li>
          </ol>
        </p>
        <button onClick={() => closeTerms(false)}>Przeczytałem!</button>
      </div>
    </div>
  );
};

export default Terms;
