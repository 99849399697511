import React, { useEffect, useState } from "react";
import "./oneItemModal.scss";
import { addToCart } from "../../redux/cartReducer";
import { useDispatch } from "react-redux";
import useFetch from "../../hooks/useFetch";

const OneItemModal = ({ closeModal, title, desc, nam, pri }) => {
  const dispatch = useDispatch();
  const [cat, setCat] = useState(
    title === "DLA NIEJ" ? "zapiekanki" : "burgery"
  );
  const [addedIngredients, setAddedIngredients] = useState([]);
  const [excludedIngredients, setExcludedIngredients] = useState([]);
  const [saladAddedIngredients, setSaladAddedIngredients] = useState([]);
  const [saladExcludedIngredients, setSaladExcludedIngredients] = useState([]);
  const [price, setPrice] = useState(pri);
  const [priceAdded, setPriceAdded] = useState(0);
  const totalPrice = price + priceAdded;
  const [name, setName] = useState();
  const [ingredients, setIngredients] = useState("");
  const [sos, ...rest] = ingredients;
  const { data: dataIngredients } = useFetch(
    `/ingredients/category/${cat}/${rest}`
  );
  // console.log(dataIngredients);

  const { data: dataSaladIngredients } = useFetch(
    `/ingredients/category/sałatki/a`
  );
  // console.log(dataSaladIngredients);
  const saladIngredients = [
    "mix sałat",
    "czerwona cebula",
    "słonecznik",
    "pomidor koktajlowy",
  ];

  const [tooMuch, setTooMuch] = useState(false);

  const { data, loading } = useFetch(`/products/category/${cat}`);

  let dataProducts = data.slice(0, data.length);
  dataProducts = dataProducts.filter(
    (item) =>
      !item.name.includes("mega") &&
      !item.name.includes("giga") &&
      !item.name.includes("zestaw")
  );

  const [info, setInfo] = useState({
    id: nam,
    img: "https://res.cloudinary.com/dqknlkpku/image/upload/v1700072781/upload/znzgrmrbpugdiroiefkd.jpg",
    category: "promocje",
    firstHalf: {
      addedIngredients: [],
      excludedIngredients: [],
    },
    secondHalf: {
      addedIngredients2: [],
      excludedIngredients2: [],
    },
    taste: "",
    drink: "",
    crust: "",
    size: "medium",
    quantity: 1,
  });

  const handleName = (e, ingredient) => {
    document.querySelectorAll("input.item").forEach((el) => {
      if (el.value !== ingredient.name) {
        el.checked = false;
      }
    });
    if (cat !== "zapiekanki") {
      setName("DLA NIEGO: " + ingredient.name + " + frytki + sałatka");
      info.category = "burgery";
    } else {
      setName("DLA NIEJ: " + ingredient.name + " + frytki + sałatka");
      info.category = "zapiekanki";
    }
    setIngredients(ingredient.ingredients);
    console.log(ingredients);
    setAddedIngredients([]);
    document.querySelectorAll("input.addons").forEach((el) => {
      if (el.value !== ingredient.name) {
        el.checked = false;
      }
    });
    setExcludedIngredients([]);
    document.querySelectorAll("input.ingr").forEach((el) => {
      el.checked = true;
    });
    setPrice(pri);
  };

  const handleSalad = (e, ingredient) => {
    const checked = e.target.checked;
    if (checked) {
      setSaladExcludedIngredients(
        excludedIngredients.filter((item) => item !== ingredient)
      );
    } else {
      setSaladExcludedIngredients((prev) => [...prev, e.target.name]);
    }
  };
  console.log(saladAddedIngredients);

  const handleSaladInclude = (e, addon) => {
    console.log(addon);
    console.log(e.target);
    const checked = e.target.checked;
    if (checked) {
      setPriceAdded(priceAdded + parseInt(e.target.value));
      setSaladAddedIngredients((prev) => [...prev, e.target.name]);
    } else {
      setPriceAdded(priceAdded - parseInt(e.target.value));
      setSaladAddedIngredients(
        saladAddedIngredients.filter((item) => item !== addon.name)
      );
    }
  };

  const handleExclude = (e, ingredient) => {
    const checked = e.target.checked;
    if (checked) {
      setExcludedIngredients(
        excludedIngredients.filter((item) => item !== ingredient)
      );
    } else {
      setExcludedIngredients((prev) => [...prev, e.target.id]);
    }
  };

  const handleInclude = (e, addon) => {
    console.log(addon);
    const checked = e.target.checked;
    if (checked) {
      if (cat !== "zapiekanki") {
        setPrice(price + parseInt(e.target.value));
        setAddedIngredients((prev) => [...prev, e.target.id]);
      } else {
        if (addedIngredients.length <= 2) {
          setPrice(price + parseInt(e.target.value));
          setAddedIngredients((prev) => [...prev, e.target.id]);
        } else {
          setTooMuch(!tooMuch);
        }
      }
    } else {
      setPrice(price - parseInt(e.target.value));
      setAddedIngredients(
        addedIngredients.filter((item) => item !== addon.name)
      );
    }
  };

  useEffect(() => {
    if (cat === "zapiekanki") {
      document.querySelectorAll("input.addons").forEach((el) => {
        if (el.value) {
          el.checked = false;
        }
      });
      addedIngredients.forEach((addedIngredientName) => {
        document.querySelectorAll("input.addons").forEach((el) => {
          console.log(el);
          if (el.id === addedIngredientName) {
            el.checked = true;
          }
        });
      });
    }
  }, [addedIngredients, tooMuch]);

  const scrollToTop = () => {
    closeModal(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="hompageModalContainer">
        <div className="modalBox">
          <div className="titleModalHompage">
            <div className="leftTitle">
              <h5>{name}</h5>
            </div>
            <div className="rightTitle">
              <button className="closeButton" onClick={() => closeModal(false)}>
                X
              </button>
            </div>
          </div>
          <div className="modalBoxContent">
            <p>{desc}</p>
                                    
            <div className="options">
              <h4>{cat === "zapiekanki" ? "Zapiekanka do wyboru:" : "Burger do wyboru:"}</h4>
              <ul className="items">
                  {loading
          ? "Trwa ładowanie menu..."
          : 
dataProducts.filter((ingredient) => ingredient.isAvailable)
                  .length > 0
                  ? dataProducts
                      .filter((ingredient) => ingredient.isAvailable)
                      .map((ingredient, index) => (
                        <li key={index}>
                          <input
                            className="item"
                            type="checkbox"
                            id={"id" + ingredient.name}
                            value={ingredient.name}
                            onChange={(e) => handleName(e, ingredient)}
                          />
                          <label htmlFor={"id" + ingredient.name}>
                            {ingredient.name}
                          </label>
                        </li>
                      ))
                  : "Przepraszamy, produkty z tej kategorii są obecnie niedostępne."}
              </ul>
            </div>
            {ingredients.length > 0 && (
              <div className="options">
                <h4>Składniki:</h4>
                <ul className="items">
                  {ingredients.map((ingredient, index) => (
                    <li key={index}>
                      <input
                        className="ingr"
                        type="checkbox"
                        id={ingredient}
                        value={ingredient}
                        checked={!excludedIngredients.includes(ingredient)}
                        onClick={(e) => handleExclude(e, ingredient)}
                      />
                      <label htmlFor={ingredient}>{ingredient}</label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {dataIngredients.length > 0 && (
              <>
                <div className="options">
                  <h4>Dodatki:</h4>
                  <ul className="items">
                    {dataIngredients
                      .filter((ingredient) => ingredient.isAvailable)
                      .map((addon) => (
                        <li key={addon?._id}>
                          <input
                            className="addons"
                            type="checkbox"
                            id={addon.name}
                            value={addon.price[0]}
                            checked={addedIngredients.includes(addon.name)}
                            onClick={(e) => handleInclude(e, addon)}
                          />
                          <label htmlFor={addon.name}>{addon.name}</label>
                        </li>
                      ))}
                  </ul>
                </div>
              </>
            )}
   {saladIngredients.length > 0 && (
              <div className="options">
                <h4>Sałatka:</h4>
                <ul className="items">
                  {saladIngredients.map((ingredient, index) => (
                    <li key={index}>
                      <input
                        className="ingr"
                        type="checkbox"
                        id={ingredient+"2"}
                        name={ingredient}
                        value={ingredient}
                        checked={!saladExcludedIngredients.includes(ingredient)}
                        onClick={(e) => handleSalad(e, ingredient)}
                      />
                      <label htmlFor={ingredient+"2"}>{ingredient}</label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {dataSaladIngredients.length > 0 && (
              <>
                <div className="options">
                  <h4>Dodatki:</h4>
                  <ul className="items">
                    {dataSaladIngredients
                      .filter((ingredient) => ingredient.isAvailable)
                      .map((addon) => (
                        <li key={addon?._id+"2"}>
                          <input
                            className="addonsSalad"
                            type="checkbox"
                            id={addon.name+"2"}
                            name={addon.name}
                            value={addon.price[0]}
                            checked={saladAddedIngredients.includes(addon.name)}
                            onClick={(e) => handleSaladInclude(e, addon)}
                          />
                          <label htmlFor={addon.name+"2"}>{addon.name}</label>
                        </li>
                      ))}
                  </ul>
                </div>
              </>
            )}
            <button
              className="addToCartButton"
              disabled={name ? false : true}
              onClick={() => {
                dispatch(
                  addToCart({
                    ...info,
                    name,
                    addedIngredients,
                    excludedIngredients,
                    sup: 0.31, 
                    price: totalPrice,
                    firstItem: {
                      name: "sałatka",
                      ingredients: "",
                      addedIngredients: saladAddedIngredients,
                      excludedIngredients: saladExcludedIngredients,
                      crust: "",
                    },
                    secondItem: {
                      name: "",
                      ingredients: "",
                      addedIngredients: "",
                      excludedIngredients: "",
                      crust: "",
                    },
                    thirdItem: {
                      name: "",
                      ingredients: "",
                      addedIngredients: "",
                      excludedIngredients: "",
                      crust: "",
                    },
                    fourthItem: {
                      name: "",
                      ingredients: "",
                      addedIngredients: "",
                      excludedIngredients: "",
                      crust: "",
                    },
                  })
                );
                scrollToTop();
                console.log(info);
              }}
            >
              <p className="price">{totalPrice.toFixed(2)} zł</p>Dodaj do koszyka
            </button>
          </div>
        </div>
        <div
          className="backgroundModal"
          onClick={() => closeModal(false)}
        ></div>
      </div>
    </>
  );
};

export default OneItemModal;
