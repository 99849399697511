export const options = [
    { value: "kościan", label: "Kościan", id: "city", strefa: "A" },
    { value: "kawczyn", label: "Kawczyn", id: "city", strefa: "A" },
    { value: "kiełczewo", label: "Kiełczewo", id: "city", strefa: "A" },
    { value: "kurowo", label: "Kurowo", id: "city", strefa: "A" },
    { value: "kurza góra", label: "Kurza Góra", id: "city", strefa: "A" },
    { value: "sierakowo", label: "Sierakowo", id: "city", strefa: "A" },
    { value: "pelikan", label: "Pelikan", id: "city", strefa: "A" },
    { value: "pianowo", label: "Pianowo", id: "city", strefa: "A" },
    { value: "nowy lubosz", label: "Nowy Lubosz", id: "city", strefa: "A" },
    {
      value: "nowe oborzyska",
      label: "Nowe Oborzyska",
      id: "city",
      strefa: "A",
    },
    {
      value: "stare oborzyska",
      label: "Stare Oborzyska",
      id: "city",
      strefa: "A",
    },

    { value: "bonikowo", label: "Bonikowo", id: "city", strefa: "B" },
    { value: "jasień", label: "Jasień", id: "city", strefa: "B" },
    { value: "słonin", label: "Słonin", id: "city", strefa: "B" },
    { value: "betkowo", label: "Betkowo", id: "city", strefa: "B" },
    { value: "witkówki", label: "Witkówki", id: "city", strefa: "B" },
    { value: "stary lubosz", label: "Stary Lubosz", id: "city", strefa: "B" },
    { value: "darnowo", label: "Darnowo", id: "city", strefa: "B" },
    { value: "racot", label: "Racot", id: "city", strefa: "B" },
    { value: "nacław", label: "Nacław", id: "city", strefa: "B" },
    { value: "czarkowo", label: "Czarkowo", id: "city", strefa: "B" },
    { value: "kokorzyn", label: "Kokorzyn", id: "city", strefa: "B" },
    { value: "szczodrowo", label: "Szczodrowo", id: "city", strefa: "B" },
    { value: "kobylniki", label: "Kobylniki", id: "city", strefa: "B" },

    { value: "jarogniewice", label: "Jarogniewice", id: "city", strefa: "C" },
    { value: "piotrkowice", label: "Piotrkowice", id: "city", strefa: "C" },
    { value: "piechanin", label: "Piechanin", id: "city", strefa: "C" },
    { value: "głuchowo", label: "Głuchowo", id: "city", strefa: "C" },
    { value: "czempiń", label: "Czempiń", id: "city", strefa: "C" },
    { value: "nowe borówko", label: "Nowe Borówko", id: "city", strefa: "C" },
    { value: "stare borówko", label: "Stare Borówko", id: "city", strefa: "C" },
    { value: "borowo", label: "Borowo", id: "city", strefa: "C" },
    { value: "gorzyce", label: "Gorzyce", id: "city", strefa: "C" },
    { value: "stary gołębin", label: "Stary Gołębin", id: "city", strefa: "C" },
    { value: "spytkówki", label: "Spytkówki", id: "city", strefa: "C" },
    { value: "nielęgowo", label: "Nielęgowo", id: "city", strefa: "C" },
    { value: "gryżyna", label: "Gryżyna", id: "city", strefa: "C" },
    { value: "nowy dębiec", label: "Nowy Dębiec", id: "city", strefa: "C" },
  { value: "ponin", label: "Ponin", id: "city", strefa: "C" },
    { value: "widziszewo", label: "Widziszewo", id: "city", strefa: "C" },
  ];
