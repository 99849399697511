import React, { useEffect, useState } from "react";
import "./threeItemModal.scss";
import { addToCart } from "../../redux/cartReducer";
import { useDispatch } from "react-redux";
import useFetch from "../../hooks/useFetch";

const ThreeItemModal = ({ closeModal, title, nam, pri }) => {
  const dispatch = useDispatch();

  const [cat, setCat] = useState(
    title === "TRZY ZAPIEKANE CUDA"
      ? "zapiekanki"
      : title === "SMAKOWE TRÓJKI W BURGEROWEJ EDYCJI"
      ? "burgery"
      : "pizza"
  );
  const [addedIngredients, setAddedIngredients] = useState([]);
  const [excludedIngredients, setExcludedIngredients] = useState([]);
  const [price, setPrice] = useState(pri || 0);
  const [priceAdded, setPriceAdded] = useState(0);
  const [priceAdded2, setPriceAdded2] = useState(0);
  const [priceAdded3, setPriceAdded3] = useState(0);
  const totalPrice = price + priceAdded + priceAdded2 + priceAdded3;

  const [name, setName] = useState();
  const [crust, setCrust] = useState("");
  const [ingredients, setIngredients] = useState("");
  const [sos, ...rest] = ingredients;
  const { data: dataIngredients } = useFetch(
    `/ingredients/category/${cat}/${rest}`
  );
  console.log(dataIngredients);

  const [tooMuch, setTooMuch] = useState(false);


  const [firstItem, setFirstItem] = useState({
    name: "",
    ingredients: "",
    addedIngredients: "",
    excludedIngredients: "",
    crust: "",
  });
  const [secondItem, setSecondItem] = useState({
    name: "",
    ingredients: "",
    addedIngredients: "",
    excludedIngredients: "",
    crust: "",
  });
  const [thirdItem, setThirdItem] = useState({
    name: "",
    ingredients: "",
    addedIngredients: "",
    excludedIngredients: "",
    crust: "",
  });

  const [selectedItem, setSelectedItem] = useState(1);

  const { data, loading } = useFetch(`/products/category/${cat}`);
  let dataProducts = data.slice(0, data.length);
  dataProducts = dataProducts.filter(
    (item) =>
      !item.name.includes("mega") &&
      !item.name.includes("giga") &&
      !item.name.includes("na wypasie") &&
      !item.name.includes("zestaw")
  );

  const [info, setInfo] = useState({
    id: nam,
    img: "https://res.cloudinary.com/dqknlkpku/image/upload/v1700072781/upload/znzgrmrbpugdiroiefkd.jpg",
    category: "promocje",
    addedIngredients: [],
    excludedIngredients: [],
    firstHalf: {
      addedIngredients: [],
      excludedIngredients: [],
    },
    secondHalf: {
      addedIngredients2: [],
      excludedIngredients2: [],
    },
    taste: "",
    drink: "",
    crust: "",
    size: "medium",
    quantity: 1,
  });

  const handleName = (e, ingredient) => {
    setIngredients(ingredient.ingredients);
    if (selectedItem === 1) {
      setPriceAdded(0);
    }
    if (selectedItem === 2) {
      setPriceAdded2(0);
    }
    if (selectedItem === 3) {
      setPriceAdded3(0);
    }
    setAddedIngredients([]);
    setExcludedIngredients([]);
    setName(ingredient.name);
    console.log(title);
    if (cat === "pizza") {
      setCrust("tradycyjne");
    }
  };

  const handleExclude = (e, ingredient) => {
    const ingredientId = e.target.id;
  
    setExcludedIngredients((prevExcludedIngredients) => {
      const updatedExcludedIngredients = prevExcludedIngredients.includes(ingredientId)
        ? prevExcludedIngredients.filter((item) => item !== ingredientId)
        : [...prevExcludedIngredients, ingredientId];
  
      return updatedExcludedIngredients;
    });
  };

  const handleInclude = (e, addon) => {
    console.log(addon);
    const checked = e.target.checked;
    if (checked) {
      if (cat !== "zapiekanki") {
        if (selectedItem === 1) {
          setPriceAdded(priceAdded + parseInt(e.target.value));
        }
        if (selectedItem === 2) {
          setPriceAdded2(priceAdded2 + parseInt(e.target.value));
        }
        if (selectedItem === 3) {
          setPriceAdded3(priceAdded3 + parseInt(e.target.value));
        }
        // setPrice(price + parseInt(e.target.value));
        setAddedIngredients((prev) => [...prev, e.target.id]);
      } else {
        if (addedIngredients.length <= 2) {
          if (selectedItem === 1) {
            setPriceAdded(priceAdded + parseInt(e.target.value));
          }
          if (selectedItem === 2) {
            setPriceAdded2(priceAdded2 + parseInt(e.target.value));
          }
          if (selectedItem === 3) {
            setPriceAdded3(priceAdded3 + parseInt(e.target.value));
          }
          // setPrice(price + parseInt(e.target.value));
          setAddedIngredients((prev) => [...prev, e.target.id]);
        } else {
          setTooMuch(!tooMuch);
        }
      }
    } else {
      if (selectedItem === 1) {
        setPriceAdded(priceAdded - parseInt(e.target.value));
      }
      if (selectedItem === 2) {
        setPriceAdded2(priceAdded2 - parseInt(e.target.value));
      }
      if (selectedItem === 3) {
        setPriceAdded3(priceAdded3 - parseInt(e.target.value));
      }
      // setPrice(price - parseInt(e.target.value));
      setAddedIngredients(
        addedIngredients.filter((item) => item !== addon.name)
      );
    }
  };

  const handleCrust = (e) => {
    setCrust(e.target.dataset.value);
  };

  const scrollToTop = () => {
    closeModal(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const switchToItem = (itemNumber) => {
    setSelectedItem(itemNumber);

    if (itemNumber === 1) {
      setName(firstItem.name);
      setCrust(firstItem.crust);
      setIngredients(firstItem.ingredients);
      setAddedIngredients(firstItem.addedIngredients);
      setExcludedIngredients(firstItem.excludedIngredients);
    }
    if (itemNumber === 2) {
      setName(secondItem.name);
      setCrust(secondItem.crust);
      setIngredients(secondItem.ingredients);
      setAddedIngredients(secondItem.addedIngredients);
      setExcludedIngredients(secondItem.excludedIngredients);
    }
    if (itemNumber === 3) {
      setName(thirdItem.name);
      setCrust(thirdItem.crust);
      setIngredients(thirdItem.ingredients);
      setAddedIngredients(thirdItem.addedIngredients);
      setExcludedIngredients(thirdItem.excludedIngredients);
    }
  };

  const updateItem = () => {
    if (name) {
      if (selectedItem === 1) {
        setFirstItem({
          name,
          ingredients,
          addedIngredients,
          excludedIngredients,
          crust,
        });
      } else if (selectedItem === 2) {
        setSecondItem({
          name,
          ingredients,
          addedIngredients,
          excludedIngredients,
          crust,
        });
      } else if (selectedItem === 3) {
        setThirdItem({
          name,
          ingredients,
          addedIngredients,
          excludedIngredients,
          crust,
        });
      }
    }
  };

  useEffect(() => {
    updateItem();
  }, [addedIngredients, excludedIngredients, crust]);

  return (
    <>
      <div className="hompageModalContainer">
        <div className="modalBox">
          <div className="titleModalHompage">
            <div className="leftTitle">
              <h5>{nam}</h5>
            </div>
            <div className="rightTitle">
              <button className="closeButton" onClick={() => closeModal(false)}>
                X
              </button>
            </div>
          </div>
          <div className="modalBoxContent">
            <div className="selectedItems">
              <div className="items">
                <div
                  className={selectedItem === 1 ? "activeItem" : "item"}
                  onClick={() => switchToItem(1)}
                >
                  <p className="itemName">
                    {firstItem.name ? firstItem.name : "pierwszy produkt"}
                  </p>
                  {firstItem.crust && (
                    <p className="itemDetail">Ciasto: {firstItem.crust}</p>
                  )}
                  {firstItem.addedIngredients.length > 0 && (
                    <p className="itemDetail">
                      Dodatki: {firstItem.addedIngredients.join(", ")}
                    </p>
                  )}
                  {firstItem.excludedIngredients.length > 0 && (
                    <p className="itemDetail">
                      Minus: {firstItem.excludedIngredients.join(", ")}
                    </p>
                  )}
                </div>
                <div
                  className={selectedItem === 2 ? "activeItem" : "item"}
                  onClick={() => switchToItem(2)}
                >
                  <p className="itemName">
                    {secondItem.name ? secondItem.name : "drugi produkt"}
                  </p>
                  {secondItem.crust && (
                    <p className="itemDetail">Ciasto: {secondItem.crust}</p>
                  )}
                  {secondItem.addedIngredients.length > 0 && (
                    <p className="itemDetail">
                      Dodatki: {secondItem.addedIngredients.join(", ")}
                    </p>
                  )}
                  {secondItem.excludedIngredients.length > 0 && (
                    <p className="itemDetail">
                      Minus: {secondItem.excludedIngredients.join(", ")}
                    </p>
                  )}
                </div>
                <div
                  className={selectedItem === 3 ? "activeItem" : "item"}
                  onClick={() => switchToItem(3)}
                >
                  <p className="itemName">
                    {thirdItem.name ? thirdItem.name : "trzeci produkt"}
                  </p>
                  {thirdItem.crust && (
                    <p className="itemDetail">Ciasto: {thirdItem.crust}</p>
                  )}
                  {thirdItem.addedIngredients.length > 0 && (
                    <p className="itemDetail">
                      Dodatki: {thirdItem.addedIngredients.join(", ")}
                    </p>
                  )}
                  {thirdItem.excludedIngredients.length > 0 && (
                    <p className="itemDetail">
                      Minus: {thirdItem.excludedIngredients.join(", ")}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="options">
              <ul className="items">
                  {loading
          ? "Trwa ładowanie menu..."
          : 
dataProducts.filter((ingredient) => ingredient.isAvailable)
                  .length > 0
                  ? dataProducts
                      .filter((ingredient) => ingredient.isAvailable)
                      .map((ingredient, index) => (
                        <li key={index}>
                          <input
                            className="item"
                            type="checkbox"
                            id={"id" + ingredient.name}
                            checked={ingredient.name === name}

                            value={ingredient.name}
                            onChange={(e) => handleName(e, ingredient)}
                          />
                          <label htmlFor={"id" + ingredient.name}>
                            {ingredient.name}
                          </label>
                        </li>
                      ))
                  : "Przepraszamy, produkty z tej kategorii są obecnie niedostępne."}
              </ul>
            </div>
            {cat === "pizza" && name && (
              <>
                <div className="options">
                  <h4>Wybierz ciasto:</h4>
                  <div className="optionsBox">
                    <div
                      className={
                        crust === "cienkie" ? "sizeOption active" : "sizeOption"
                      }
                      data-value="cienkie"
                      onClick={(e) => handleCrust(e)}
                    >
                      Cienkie
                    </div>
                    <div
                      className={
                        crust === "tradycyjne"
                          ? "sizeOption active"
                          : "sizeOption"
                      }
                      data-value="tradycyjne"
                      onClick={(e) => handleCrust(e)}
                    >
                      Tradycyjne
                    </div>
                    <div
                      className={
                        crust === "grube" ? "sizeOption active" : "sizeOption"
                      }
                      data-value="grube"
                      onClick={(e) => handleCrust(e)}
                    >
                      Grube
                    </div>
                  </div>
                </div>
              </>
            )}
            {ingredients.length > 0 && (
              <div className="options">
                <h4>Składniki:</h4>
                <ul className="items">
                  {ingredients.map((ingredient, index) => (
                    <li key={index}>
                      <input
                        className="ingr"
                        type="checkbox"
                        id={ingredient}
                        value={ingredient}
                        checked={!excludedIngredients.includes(ingredient)}
                        onClick={(e) => handleExclude(e, ingredient)}
                      />
                      <label htmlFor={ingredient}>{ingredient}</label>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {dataIngredients.length > 0 && name && (
              <>
                <div className="options">
                  <h4>Dodatki:</h4>
                  <ul className="items">
                    {dataIngredients
                      .filter((ingredient) => ingredient.isAvailable)
                      .map((addon) => (
                        <li key={addon?._id}>
                          <input
                            className="addons"
                            type="checkbox"
                            id={addon.name}
                            value={addon.price[0]}
                            checked={addedIngredients.includes(addon.name)}
                            onChange={(e) => handleInclude(e, addon)}
                          />
                          <label htmlFor={addon.name}>{addon.name}</label>
                        </li>
                      ))}
                  </ul>
                </div>
              </>
            )}
            <button
              className="addToCartButton"
              disabled={
                firstItem.name && secondItem.name && thirdItem.name
                  ? false
                  : true
              }
              onClick={() => {
                let selectedCartItems = {
                  ...info,
                  name: nam,
                  sup: 0,
                  price: totalPrice,
                  firstItem: firstItem,
                  secondItem: secondItem,
                  thirdItem: thirdItem,
                  fourthItem: {
                    name: "",
                    ingredients: "",
                    addedIngredients: "",
                    excludedIngredients: "",
                    crust: "",
                  },
                };
                dispatch(addToCart(selectedCartItems));
                console.log(selectedCartItems);
                scrollToTop();
              }}
            >
              <p className="price">{totalPrice.toFixed(2)} zł</p>Dodaj do
              koszyka
            </button>
          </div>
        </div>
        <div
          className="backgroundModal"
          onClick={() => closeModal(false)}
        ></div>
      </div>
    </>
  );
};

export default ThreeItemModal;
