import React from "react";
import "./privacy.scss";
import Sidebar from "../../components/sidebar/Sidebar";

const Privacy = () => {
  return (
    <div className="privacy">
      <Sidebar />
      <div className="privacyContainer">
        <div className="privacyWrapper">
          <h3>1. Informacje ogólne.</h3>
          <ol>
            <li>
              Operatorem Serwisu www.omika.pl&nbsp;jest Przedsiębiorstwo
              handlowo usługowe Tomasz Walich
            </li>
            <li>
              Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i
              ich zachowaniu w następujący sposób:
              <ol>
                <li>
                  Poprzez dobrowolnie wprowadzone w formularzach informacje.
                </li>
                <li>
                  Poprzez zapisywanie w urządzeniach końcowych pliki cookie
                  (tzw. "ciasteczka").
                </li>
                <li>
                  Poprzez gromadzenie logów serwera www przez operatora
                  hostingowego Pizzerii Omika, funkcjonującego pod adresem
                  www.omika.pl
                </li>
              </ol>
            </li>
          </ol>
          <h3>2. Informacje w formularzach.</h3>
          <ol>
            <li>
              Serwis zbiera informacje podane dobrowolnie przez użytkownika.
            </li>
            <li>
              Serwis może zapisać ponadto informacje o parametrach połączenia
              (oznaczenie czasu, adres IP)
            </li>
            <li>
              Dane w formularzu nie są udostępniane podmiotom trzecim inaczej,
              niż za zgodą użytkownika.
            </li>
            <li>
              Dane podane w formularzu mogą stanowić zbiór potencjalnych
              klientów, zarejestrowany przez Operatora Serwisu w rejestrze
              prowadzonym przez Generalnego Inspektora Ochrony Danych Osobowych.
            </li>
            <li>
              Dane podane w formularzu są przetwarzane w celu wynikającym z
              funkcji konkretnego formularza, np w celu dokonania procesu
              obsługi zgłoszenia serwisowego lub kontaktu handlowego (
              <span>art. 6 ust. 1 lit f RODO)</span>.
            </li>
            <li>
              Dane podane w formularzach mogą być przekazane podmiotom
              technicznie realizującym niektóre usługi – w szczególności dotyczy
              to przekazywania informacji o posiadaczu rejestrowanej domeny do
              podmiotów będących operatorami domen internetowych (przede
              wszystkim Naukowa i Akademicka Sieć Komputerowa j.b.r – NASK),
              serwisów obsługujących płatności lub też innych podmiotów, z
              którymi Operator Serwisu w tym zakresie współpracuje (
              <span>art. 6 ust. 1 lit f RODO)</span>.
            </li>
          </ol>
          <h3>3. Informacja o plikach cookies.</h3>
          <ol>
            <li>Serwis korzysta z plików cookies.</li>
            <li>
              Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w
              szczególności pliki tekstowe, które przechowywane są w urządzeniu
              końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze
              stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę
              strony internetowej, z której pochodzą, czas przechowywania ich na
              urządzeniu końcowym oraz unikalny numer.
            </li>
            <li>
              Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika
              Serwisu pliki cookies oraz uzyskującym do nich dostęp jest
              operator Serwisu.
            </li>
            <li>
              Pliki cookies wykorzystywane są w następujących celach:
              <ol>
                <li>
                  tworzenia statystyk, które pomagają zrozumieć, w jaki sposób
                  Użytkownicy Serwisu korzystają ze stron internetowych, co
                  umożliwia ulepszanie ich struktury i zawartości;
                </li>
                <li>
                  utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki
                  której Użytkownik nie musi na każdej podstronie Serwisu
                  ponownie wpisywać loginu i hasła;
                </li>
                <li>
                  określania profilu użytkownika w celu wyświetlania mu
                  dopasowanych materiałów w sieciach reklamowych, w
                  szczególności sieci Google.
                </li>
              </ol>
            </li>
            <li>
              W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików
              cookies: „sesyjne” (session cookies) oraz „stałe” (persistent
              cookies). Cookies „sesyjne” są plikami tymczasowymi, które
              przechowywane są w urządzeniu końcowym Użytkownika do czasu
              wylogowania, opuszczenia strony internetowej lub wyłączenia
              oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies
              przechowywane są w urządzeniu końcowym Użytkownika przez czas
              określony w parametrach plików cookies lub do czasu ich usunięcia
              przez Użytkownika.
            </li>
            <li>
              Oprogramowanie do przeglądania stron internetowych (przeglądarka
              internetowa) zazwyczaj domyślnie dopuszcza przechowywanie plików
              cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu
              mogą dokonać zmiany ustawień w tym zakresie. Przeglądarka
              internetowa umożliwia usunięcie plików cookies. Możliwe jest także
              automatyczne blokowanie plików cookies Szczegółowe informacje na
              ten temat zawiera pomoc lub dokumentacja przeglądarki
              internetowej.
            </li>
            <li>
              Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre
              funkcjonalności dostępne na stronach internetowych Serwisu.
            </li>
            <li>
              Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika
              Serwisu i wykorzystywane mogą być również przez współpracujących z
              operatorem Serwisu reklamodawców oraz partnerów.
            </li>
            <li>
              Zalecamy przeczytanie polityki ochrony prywatności tych firm, aby
              poznać zasady korzystania z plików cookie wykorzystywane w
              statystykach:{" "}
              <span>
                <a href="http://www.google.pl/intl/pl/analytics/privacyoverview.html">
                  Polityka ochrony prywatności Google Analytics
                </a>
              </span>
            </li>
            <li>
              Pliki cookie mogą być wykorzystane przez sieci reklamowe, w
              szczególności sieć Google, do wyświetlenia reklam dopasowanych do
              sposobu, w jaki użytkownik korzysta z Serwisu. W tym celu mogą
              zachować informację o ścieżce nawigacji użytkownika lub czasie
              pozostawania na danej stronie.
            </li>
            <li>
              W zakresie informacji o preferencjach użytkownika gromadzonych
              przez sieć reklamową Google użytkownik może przeglądać i edytować
              informacje wynikające z plików cookies przy pomocy narzędzia:{" "}
              <span>
                <a href="https://www.google.com/ads/preferences/">
                  https://www.google.com/ads/preferences/
                </a>
              </span>
            </li>
          </ol>
          <h3>4. Logi serwera.</h3>
          <ol>
            <li>
              Informacje o niektórych zachowaniach użytkowników podlegają
              logowaniu w warstwie serwerowej. Dane te są wykorzystywane
              wyłącznie w celu administrowania serwisem oraz w celu zapewnienia
              jak najbardziej sprawnej obsługi świadczonych usług hostingowych.
            </li>
            <li>
              Przeglądane zasoby identyfikowane są poprzez adresy URL. Ponadto
              zapisowi mogą podlegać:
              <ol>
                <li>czas nadejścia zapytania,</li>
                <li>czas wysłania odpowiedzi,</li>
                <li>
                  nazwę stacji klienta – identyfikacja realizowana przez
                  protokół HTTP,
                </li>
                <li>
                  informacje o błędach jakie nastąpiły przy realizacji
                  transakcji HTTP,
                </li>
                <li>
                  adres URL strony poprzednio odwiedzanej przez użytkownika
                  (referer link) – w przypadku gdy przejście do Serwisu
                  nastąpiło przez odnośnik,
                </li>
                <li>informacje o przeglądarce użytkownika,</li>
                <li>Informacje o adresie IP.</li>
              </ol>
            </li>
            <li>
              Dane powyższe nie są kojarzone z konkretnymi osobami
              przeglądającymi strony.
            </li>
            <li>
              Dane powyższe są wykorzystywane jedynie dla celów administrowania
              serwerem.
            </li>
          </ol>
          <h3>5. Udostępnienie danych.</h3>
          <ol>
            <li>
              Dane podlegają udostępnieniu podmiotom zewnętrznym wyłącznie w
              granicach prawnie dozwolonych.
            </li>
            <li>
              Dane umożliwiające identyfikację osoby fizycznej są udostępniane
              wyłączenie za zgodą tej osoby.
            </li>
            <li>
              Operator może mieć obowiązek udzielania informacji zebranych przez
              Serwis upoważnionym organom na podstawie zgodnych z prawem żądań w
              zakresie wynikającym z żądania.
            </li>
          </ol>
          <h3>
            6. Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać
            zgodę?
          </h3>
          <ol>
            <li>
              Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić
              ustawienia przeglądarki. Zastrzegamy, że wyłączenie obsługi plików
              cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa,
              utrzymania preferencji użytkownika może utrudnić, a w skrajnych
              przypadkach może uniemożliwić korzystanie ze stron www
            </li>
            <li>
              W celu zarządzania ustawieniami cookies wybierz z listy poniżej
              przeglądarkę internetową/ system i postępuj zgodnie z
              instrukcjami:
              <ol>
                <li>
                  <span>
                    <a href="http://support.microsoft.com/kb/196955">
                      Internet Explorer
                    </a>
                  </span>
                </li>
                <li>
                  <span>
                    <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">
                      Chrome
                    </a>
                  </span>
                </li>
                <li>
                  <span>
                    <a href="http://support.apple.com/kb/PH5042">Safari</a>
                  </span>
                </li>
                <li>
                  <span>
                    <a href="http://support.mozilla.org/pl/kb/W%C5%82%C4%85czanie%20i%20wy%C5%82%C4%85czanie%20obs%C5%82ugi%20ciasteczek">
                      Firefox
                    </a>
                  </span>
                </li>
                <li>
                  <span>
                    <a href="http://help.opera.com/Windows/12.10/pl/cookies.html">
                      Opera
                    </a>
                  </span>
                </li>
                <li>
                  <span>
                    <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">
                      Android
                    </a>
                  </span>
                </li>
                <li>
                  <span>
                    <a href="http://support.apple.com/kb/HT1677?viewlocale=pl_PL">
                      Safari (iOS)
                    </a>
                  </span>
                </li>
                <li>
                  <span>
                    <a href="http://www.windowsphone.com/pl-pl/how-to/wp7/web/changing-privacy-and-other-browser-settings">
                      Windows Phone
                    </a>
                  </span>
                </li>
                <li>
                  <span>
                    <a href="http://docs.blackberry.com/en/smartphone_users/deliverables/32004/Turn_off_cookies_in_the_browser_60_1072866_11.jsp">
                      Blackberry
                    </a>
                  </span>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
