import "./checkout.scss";
import { resetCart } from "../../redux/cartReducer";
import { AuthContext } from "../../context/AuthContext";
import Select from "react-select";
import Creatable from "react-select/creatable";
import OrderItems from "../orderItems/OrderItems";
import { streets } from "../../data/streets";
import { options } from "../../data/cities";

import { useContext, useEffect,  useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import useFetch from "../../hooks/useFetch";
import Terms from "../terms/Terms";

const Checkout = ({ closeCheckout }) => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
  });

  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: bankData } = useFetch(`/orders/onlinepayment`);
  const [bankId, setBankId] = useState();
  const [delivery, setDelivery] = useState(true);
  const [err, setErr] = useState({});
  const [tip, setTip] = useState(0);
  const [info, setInfo] = useState({
    customerId: user?._id || undefined,
    firstName: user?.firstName || undefined,
    lastName: user?.lastName || undefined,
    email: user?.email || undefined,
    homeNumber: user?.homeNumber || undefined,
    phone: user?.phone || undefined,
    deliveryTime: "jak najszybciej",
    paymentMethod: "cash",
    status: "pending",
  });
  const products = useSelector((state) => state.cart.products);
  const { data: strefa } = useFetch(`/ingredients/category/dowóz/a`);

  const { data: voucherData } = useFetch(`/vouchers`);
  const [voucherName, setVoucherName] = useState("");
  const [voucherAmount, setVoucherAmount] = useState();
  const [voucherPercentage, setVoucherPercentage] = useState();
  const [voucherError, setVoucherError] = useState();


  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedOmikaTerms, setIsCheckedOmikaTerms] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);

  let actualTime = new Date();

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleCheckboxChangeTerms = (e) => {
    setIsCheckedOmikaTerms(e.target.checked);
    validate();
  };

  const getTimeRange = (delivery, startTime) => {
    const start = new Date(startTime);
    const end = new Date();
    if (start.getHours() < 13) {
      start.setHours(13);
      start.setMinutes(0);
    }
    // console.log(start);
    // zmienic na 21 !!!!!
    end.setHours(21, 20, 0);

    if (delivery === "false") {
      start.setMinutes(start.getMinutes() + 20);
    } else {
      start.setMinutes(start.getMinutes() + 50);
    }

    let timeRange = [];
    while (start <= end) {
      let x = start.getMinutes();
      let y = 0;
      while (x > y && x < 60) {
        start.setMinutes(y + 10);
        y = y + 10;
      }
      // start.setMinutes(start.getMinutes() + 5);

      timeRange.push(
        new Date(start).toLocaleTimeString("pl-PL", {
          hour: "2-digit",
          minute: "2-digit",
        }) +
          " - " +
          new Date(
            start.setMinutes(start.getMinutes() + 10)
          ).toLocaleTimeString("pl-PL", {
            hour: "2-digit",
            minute: "2-digit",
          })
      );
    }
    return timeRange;
  };
  let timeRange = getTimeRange(delivery, new Date());

  const deliveryTotal = () => {
    let deliveryCost = 0;
    if (delivery !== "false") {
      if (info.strefa === "A") {
        if (
          info.city === "kościan" &&
          (info.street === "poznańska" ||
            info.street === "szkolna" ||
            info.street === "czempińska" ||
            info.street === "czereśniowa" ||
            info.street === "wiśniowa" ||
            info.street === "górna" ||
            info.street === "pszenna" ||
            info.street === "polna" ||
            info.street === "północna" ||
            info.street === "krańcowa" ||
            info.street === "zielona" ||
            info.street === "osiedle konstytucji 3 maja")
        ) {
          deliveryCost += strefa[1].price[0];
        } else {
          deliveryCost += strefa[2].price[0];
        }
      }
      if (info.strefa === "B") {
        deliveryCost += strefa[3].price[0];
      }
      if (info.strefa === "C") {
        deliveryCost += strefa[4].price[0];
      }
    }
    return deliveryCost.toFixed(2);
  };

  const cartTotal = () => {
    let cartTotal = 0;
    products.forEach((item) => (cartTotal += item.quantity * item.price));
    return cartTotal.toFixed(2);
  };

 const supTotal = () => {
    let supTotal = 0;
    products.forEach((item) => (supTotal += item.quantity * item.sup));
    return supTotal.toFixed(2);
  }

  let deliveryCost = deliveryTotal();
  let cartAmount = cartTotal();
  let supAmount = supTotal();
  let tipAmount = (
    (parseFloat(cartAmount) + parseFloat(deliveryCost)) *
    tip
  ).toFixed(2);
let discountAmount = 0;
  if (voucherPercentage > 0) {
    discountAmount = (
      (parseFloat(cartAmount) + parseFloat(deliveryCost)) *
      voucherPercentage *
      0.01
    ).toFixed(2);
  }
  if (voucherAmount > 0) {
    discountAmount = voucherAmount.toFixed(2);
  }
  let totalPrice = (
    parseFloat(cartAmount) +
    parseFloat(supAmount) +
    parseFloat(tipAmount) +
    parseFloat(deliveryCost) -
    parseFloat(discountAmount)
  ).toFixed(2);

if (totalPrice < 0) {
    totalPrice = 0;
  }

  const handleChange = (e) => {
    if (e.target) {
      setInfo((prev) => ({ ...prev, [e.target.id]: e.target.value }));
    } else {
      if (e.id) {
        setInfo((prev) => ({ ...prev, [e.id]: e.value }));
        if (e.strefa) {
          setInfo((prev) => ({
            ...prev,
            [e.id]: e.value,
            strefa: e.strefa,
          }));
        }
      } else {
        setInfo((prev) => ({ ...prev, street: e.value }));
      }
    }
  };

  const handleDelivery = (e) => {
    setDelivery(e.target.value);
    timeRange = getTimeRange(e.target.value, new Date());
   //  timeRange = getTimeRange(new Date());
    setInfo((prev) => ({ ...prev, deliveryTime: e.target.value === "true" ? "jak najszybciej" : timeRange[0], }));
  };

  const validate = async () => {
    let errors = {};
    if (
      info.strefa === "A" &&
      delivery !== "false" &&
      totalPrice - deliveryCost < 30
    ) {
      errors.delivery =
        "Minimalna kwota zamówienia przy dostawie dla wybranej miejscowości musi wynosić 30zł";
    }
    if (
      info.strefa === "B" &&
      delivery !== "false" &&
      totalPrice - deliveryCost < 60
    ) {
      errors.delivery =
        "Minimalna kwota zamówienia przy dostawie dla wybranej miejscowości musi wynosić 60zł";
    }
    if (
      info.strefa === "C" &&
      delivery !== "false" &&
      totalPrice - deliveryCost < 90
    ) {
      errors.delivery =
        "Minimalna kwota zamówienia przy dostawie dla wybranej miejscowości musi wynosić 90zł";
    }
    if (!info.firstName) {
      errors.firstName = "Wpisz swoje imię!";
    }
    if (!info.lastName) {
      errors.lastName = "Wpisz swoje nazwisko!";
    }
    if (!info.phone) {
      errors.phone = "Wpisz swój numer telefonu!";
    }
    if (!info.email) {
      errors.email = "Wpisz swój adres email!";
    }
    if (delivery !== "false") {
      if (!info.street) {
        errors.street = "Wpisz swoją ulicę!";
      }
      if (!info.homeNumber) {
        errors.homeNumber = "Wpisz swój numer domu!";
      }
      if (!info.city) {
        errors.city = "Wpisz swoje miasto!";
      }
    }

    if (info.paymentMethod === "online") {
      if (isChecked === false) {
        errors.tpayTerms = "Zaakceptuj regulamin płatności online!";
      }
if (!bankId){
        errors.bankId = "Wybierz sposób płatności online!";
      }
    }

    if (!info.deliveryTime) {
      errors.deliveryTime = "Wybierz czas realizacji zamówienia!";
    }

    if (new Date().getHours() <= 8 && new Date().getMinutes() <= 50) {
      errors.hours = "Nie można składać zamówień przed otwarciem lokalu.";
    }

       if (new Date().getDay() >= 5 && new Date().getDay() <= 6) {
      if ((new Date().getHours() >= 21 && new Date().getMinutes() > 40) || (new Date().getHours() >= 22)) {
        errors.hours = "Nie można składać zamówien po zamknięciu lokalu.";
      }
    } else {
      if ((new Date().getHours() >= 21 && new Date().getMinutes() > 10) || (new Date().getHours() >= 22)) {
        errors.hours = "Nie można składać zamówien po zamknięciu lokalu.";
      }
    }

    if (strefa[0].isAvailable === false) {
      errors.closed =
        "Przepraszamy, składanie zamówień przez internet jest obecnie niedostępne.";
    }

    setErr(errors);
  };


  const handleVoucher = () => {
    console.log(voucherName);
    const voucherDetails = voucherData.find(
      (voucher) => voucher.name === voucherName
    );
    console.log(voucherDetails);
    const date = new Date().getDate();

    if (!voucherDetails) {
      setVoucherAmount(0);
      setVoucherPercentage(0);
      setVoucherError("Błędny kod");
    } else {
if(voucherDetails.quantity < 1){
        setVoucherAmount(0);
        setVoucherPercentage(0);
        setVoucherError("Voucher już został wykorzystany.");
        return
      }

      const start = new Date(voucherDetails.startDate).getDate();
      const end = new Date(voucherDetails.endDate).getDate();
      console.log(start <= date);
      console.log(date <= end);
      if (start <= date && date <= end) {
        setVoucherError("");
        if (voucherDetails.isPercentage) {
          setVoucherPercentage(voucherDetails.value);
          setVoucherAmount(0);
        } else {
          setVoucherAmount(voucherDetails.value);
          setVoucherPercentage(0);
        }
      } else {
        setVoucherError("Błędna data");
        setVoucherAmount(0);
        setVoucherPercentage(0);
      }
    }
  };

  const [disabled, setDisabled] = useState(false);


  const handleClick = async (e) => {
    e.preventDefault();
    validate();
    if (Object.keys(err).length === 0 || err === {}) {
      try {
        // const totalPrice = cartTotal();
        const newOrder = {
          ...info,
          deliveryCost,
          tip,
          tipAmount,
          voucherName,
          totalPrice,
          products,
          delivery,
          bankId,
        };
        const response = await axiosInstance.post("/orders", newOrder);

        if (response.data.paymentMethod === "online") {
          await navigate(`/order/${response.data._id}`);
          window.location.replace(response.data.url);
          // navigate(response.data.url);
          // redirect(response.data.url);
        } else {
          await navigate(`/order/${response.data._id}`);
        }
        dispatch(resetCart());
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log(err);
    }
  };


  useEffect(() => {
    if (isCheckedOmikaTerms) {
      validate();
      if (Object.keys(err).length === 0 || err === {}) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      if (Object.keys(err).length !== 0) {
        setDisabled(true);
      }
    }
  }, [err]);

  return (
    <>
      <div className="checkout">
        <div className="checkoutContainer">
          <div className="checkoutTop">
            <div className="checkoutHeader">
              <div className="checkoutHeaderLeft">
                <h1>Podsumowanie</h1>
              </div>
              <div className="checkoutHeaderRight">
                <button
                  className="closeButton"
                  onClick={() => closeCheckout(false)}
                >
                  &times;
                </button>
              </div>
            </div>
          </div>
          <div className="checkoutBottom">
            <div className="bottomLeft">
              <div className="contactData">
                <div className="formInput">
                  <label>Imię</label>
                  <input
                    type="text"
                    placeholder="Imię"
                    id="firstName"
                    defaultValue={user?.firstName}
                    onChange={handleChange}
                  />
                  {err?.firstName && !info.firstName && (
                    <span className="errorMessage">{err.firstName}</span>
                  )}
                </div>
                <div className="formInput">
                  <label>Nazwisko</label>
                  <input
                    type="text"
                    placeholder="Nazwisko"
                    id="lastName"
                    defaultValue={user?.lastName}
                    onChange={handleChange}
                  />
                  {err?.lastName && !info.lastName && (
                    <span className="errorMessage">{err.lastName}</span>
                  )}
                </div>
              </div>
              <div className="contactData">
                <div className="formInput">
                  <label>Numer telefonu</label>
                  <input
                    type="number"
                    placeholder="Numer telefonu"
                    id="phone"
                    defaultValue={user?.phone}
                    onChange={handleChange}
                  />
                  {err?.phone && !info.phone && (
                    <span className="errorMessage">{err.phone}</span>
                  )}
                </div>

                <div className="formInput">
                  <label>NIP (opcjonalnie)</label>
                  <input
                    type="number"
                    placeholder="NIP"
                    id="nip"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="emailInput">
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Adres email"
                  id="email"
                  defaultValue={user?.email}
                  onChange={handleChange}
                />
                {err?.email && !info.email && (
                  <span className="errorMessage">{err.email}</span>
                )}
              </div>
              <div className="deliveryData">
                <div className="deliveryButtons">
                  <button
                    id="delivery"
                    value={true}
                    onClick={handleDelivery}
                    className={
                      delivery !== "false"
                        ? "deliveryOption active"
                        : "deliveryOption"
                    }
                  >
                    Dostawa
                  </button>
                  <button
                    id="delivery"
                    value={false}
                    onClick={handleDelivery}
                    className={
                      delivery !== "false"
                        ? "deliveryOption"
                        : "deliveryOption active"
                    }
                  >
                    Odbiór osobisty
                  </button>
                </div>
                <div className="deliveryDetails">
                  {delivery !== "false" && (
                    <>
                      <Creatable
                        options={streets}
                        onChange={handleChange}
                        placeholder="Ulica"
                      />
                      {err?.street && !info.street && (
                        <span className="errorMessage">{err.street}</span>
                      )}
                      <div className="homeNumber">
                        <input
                          type="text"
                          placeholder="Numer domu"
                          id="homeNumber"
                          defaultValue={user?.homeNumber}
                          onChange={handleChange}
                        />
                        {err?.homeNumber && !info.homeNumber && (
                          <span className="errorMessage">{err.homeNumber}</span>
                        )}
                      </div>
                      <Select
                        options={options}
                        onChange={handleChange}
                        placeholder="Miasto"
                      />
                      {err?.city && !info.city && (
                        <span className="errorMessage">{err.city}</span>
                      )}
                      {err?.delivery && (
                        <span className="errorMessage">{err.delivery}</span>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="commentsData">
                <div className="comments">
                  <label>Uwagi do zamówienia</label>
                  <textarea
                    placeholder="Uwagi do zamówienia"
                    onChange={handleChange}
                    id="comments"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="bottomRight">
              <h1>Twoje zamówienie:</h1>
              <OrderItems products={products} editable={false} />
              <p className="totalPrice">Koszt dostawy: {deliveryCost}</p>
              <p className="totalPrice">Voucher: {discountAmount}zł</p>
              <p className="totalPrice">Napiwek: {tipAmount}zł</p>
              <p className="totalPrice">Opłata SUP: {supAmount}zł</p>
              {supAmount > 0 && <h5>W związku z dyrektywą SUP wydaną przez Unię Europejską, jesteśmy zobowiązani pobrać opłatę 0.31zł za sztukę w przypadku opakowań jednorazowych zawierających tworzywa sztuczne.</h5>}
              <p className="totalPrice">Łączny koszt: {totalPrice}zł</p>
             


              <div className="tipData">
                <p className="title">Czy chcesz dodać napiwek do zamówienia?</p>
                <div className="tipButtons">
                  <button
                    className={tip === 0 ? "tipOption active" : "tipOption"}
                    onClick={() => setTip(0)}
                  >
                    Bez napiwku
                  </button>
                  <button
                    className={tip === 0.05 ? "tipOption active" : "tipOption"}
                    onClick={() => setTip(0.05)}
                  >
                    Dodaj 5%
                  </button>
                  <button
                    className={tip === 0.1 ? "tipOption active" : "tipOption"}
                    onClick={() => setTip(0.1)}
                  >
                    Dodaj 10%
                  </button>
                </div>
              </div>
   <div className="voucherData">
                <div className="vouchers">
                  <label htmlFor="voucherName">Voucher:</label>
                  <input
                    onChange={(e) => setVoucherName(e.target.value)}
                    type="text"
                    id="voucherName"
                    defaultValue={info.voucherName}
                  />
                </div>
                <button onClick={handleVoucher}>ZASTOSUJ</button>
                <p className="error">{voucherError && voucherError}</p>
              </div>
              <div className="paymentData">
                <p className="title">Wybierz metodę płatności:</p>
                <div className="paymentButtons">
                  <button
                    id="paymentMethod"
                    value={"cash"}
                    onClick={handleChange}
                    className={
                      info.paymentMethod === "cash"
                        ? "paymentOption active"
                        : "paymentOption"
                    }
                  >
                    Gotówka
                  </button>
                  <button
                    id="paymentMethod"
                    value={"terminal"}
                    onClick={handleChange}
                    className={
                      info.paymentMethod === "terminal"
                        ? "paymentOption active"
                        : "paymentOption"
                    }
                  >
                    Kartą przy odbiorze
                  </button>
                  <button
                    id="paymentMethod"
                    value={"online"}
                    onClick={handleChange}
                    className={
                      info.paymentMethod === "online"
                        ? "paymentOption active"
                        : "paymentOption"
                    }
                  >
                    Online
                  </button>
                </div>
                {info.paymentMethod === "online" && (
                  <>
                    <div className="bankCards">
                      {Object.values(bankData).map((bank) => (
                        <div
                          className={
                            bankId === bank.id ? "bankCard active" : "bankCard"
                          }
                          key={bank.id}
                          onClick={() => setBankId(bank.id)}
                        >
                          <div className="logo">
                            <img src={bank.img} alt="bank" />
                          </div>
                        </div>
                      ))}
                    </div>
{err?.bankId && !bankId && (
                        <span className="errorMessage">{err.bankId}</span>
                      )}
                    <div className="acceptTOS">
                      <label>
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                        Przeczytałem i akceptuje{" "}
                        <a
                          href="https://tpay.com/user/assets/files_for_download/regulamin.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          regulamin płatności online Tpay
                        </a>
                      </label>
                      {err?.tpayTerms && isChecked === false && (
                        <span className="errorMessage">{err.tpayTerms}</span>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="deliveryData">
                <p className="title">
                  Wybierz czas {delivery !== "false" ? "dostawy" : "odbioru"}:
                </p>
                <select
                  id="deliveryTime"
                  className="select"
                  onChange={handleChange}
                  value={info.deliveryTime}
                >
                  {delivery !== "false" && (
                    <option value="jak najszybciej">{actualTime.getHours() < 13 ? "13:15 - 14:00" : "Jak najszybciej"}</option>
                  )}
                  {timeRange.map((option) => (
                    <option value={option} key={option}>
                      {option}
                    </option>
                  ))}
                </select>
                {err?.deliveryTime && !info.deliveryTime && (
                  <span className="errorMessage">{err.deliveryTime}</span>
                )}
              </div>
              <div className="acceptTerms">
                <label>
                  <input
                    type="checkbox"
                    checked={isCheckedOmikaTerms}
                    onChange={handleCheckboxChangeTerms}
                  />
                  Przeczytałem i akceptuje{" "}
                  <span onClick={() => setOpenTerms(!openTerms)}>
                    regulamin sklepu internetowego Omika
                  </span>
                </label>
                {openTerms && <Terms closeTerms={setOpenTerms} />}
                {err?.terms && isCheckedOmikaTerms === false && (
                  <span className="errorMessage">{err.terms}</span>
                )}
              </div>
              {err?.hours || err?.closed || isCheckedOmikaTerms === false ? (
               <> <div className="checkoutButton">
                  {err?.closed ? (
                    <span className="errorMessage">{err.closed}</span>
                  ) : (
                    <span className="errorMessage">{err.hours}</span>
                  )}
                </div>
<div className="checkoutButton">
                    <button disabled={disabled} onClick={validate}>
                      ZAMAWIAM
                    </button>
                  </div>
                </>
              ) : (
                <div className="checkoutButton">
                  <button disabled={disabled}  onClick={handleClick}>ZAMAWIAM</button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
