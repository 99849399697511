import "./reset.scss";
import axios from "axios";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

const Forget = () => {
  const [password, setPassword] = useState();
  const {id, token} = useParams();

  const axiosInstance = axios.create({baseURL: process.env.REACT_APP_API_URL, withCredentials: true})


  const navigate = useNavigate();

  const handleChange = (e) => {
    setPassword((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(`/users/reset-password/${id}/${token}`, password);
      navigate("/");
    } catch (err) {
    console.log(err);
    }
  };

  return (
    <div className="reset">
      <div className="rContainer">
        <input
          type="password"
          placeholder="Hasło"
          id="password"
          onChange={handleChange}
          className="rInput"
        />
        <button onClick={handleClick} className="rButton">
          Ustaw nowe hasło
        </button>
        <div className="goBackLink">
          <Link
            to="/"
            style={{ textDecoration: "none", color: "#777" }}
          >
            <span>Wróć na stronę główną.</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Forget;
