import React, { useState } from "react";
import "./cookie.scss";
import { useCookies } from "react-cookie";
import Privacy from "../privacy/Privacy";

const Cookie = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const giveCookieConsent = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };
  return (
    <div className="cookie">
      <p>
        Ta strona używa ciasteczek. Dalsze korzystanie ze strony oznacza, że
        zgadzasz się na ich użycie.{" "}
        <button onClick={() => setPrivacyPolicyOpen(true)}>
          Dowiedz się więcej
        </button>
      </p>
      <button onClick={giveCookieConsent}>ZGODA</button>
      <Privacy isOpen={isPrivacyPolicyOpen} onClose={() => setPrivacyPolicyOpen(false)} />
    </div>
  );
};

export default Cookie;
